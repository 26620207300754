import PropTypes from "prop-types";
import { useState } from "react";
import { Link } from 'react-router-dom';

const Header = ({ className = "" }) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const menuItems = [
    { title: 'Home', link: '/' },
    { title: 'Portfolio', link: '/portfolio' },
    { title: 'Services', link: '#', subItems: [{ title: 'Full-Stack Web Applications', link: "/fullstack" }, { title: 'Static Websites', link: "/static" }, { title: 'Bespoke Software', link: "/bespoke" }] },
    // { title: 'More', link: '#', subItems: [{ title: 'Pricing', link: "/pricing" }, { title: 'FAQ', link: "/faq" }] },
    { title: 'Pricing', link: "/pricing" }, { title: 'FAQ', link: "/faq" },
    { title: 'Contact', link: '/contact' },
  ];

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <header
      className={`self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-center text-lg text-white font-adamina ${className}`}
    >
      <div className="w-[1400px] flex flex-row items-start justify-between gap-[20px] max-w-full mq1250:w-[1319px]">
        <div className="h-[116.5px] w-[161px] flex flex-col items-start justify-start pt-[22.5px] px-0 pb-0 box-border">
          <img
            className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
            loading="lazy"
            alt=""
            src="/image-7removebgpreview-1@2x.png"
          />
        </div>
        <div className="w-full justify-end mq825:w-[360px] mqAll:w-[700px] flex flex-row items-start gap-[10px] max-w-full mq750:hidden font-montserrat">
          {menuItems.map((item, index) => (
            <div className="flex flex-col items-center h-[135px] justify-center pt-0.5 px-0 pb-0 relative" key={index} onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(null)}>
              <Link to={item.link} className={`[text-decoration:none] relative text-[inherit] py-4 flex items-center justify-center shrink-0 cursor-pointer mq825:text-xl m-3 mq825:m-1 mq1920:text-5xl mqAll:text-10xl hover:text-gray-100 ${hoveredItem === index ? "text-gray-400" : ""}`}>
                {item.title}
              </Link>
              {item.subItems && (
                <div
                  className={`absolute w-[300px] right-0 top-full z-[200] p-6     
                  [backdrop-filter:blur(11.6px)] bg-gray-800 box-border border-[1px] border-solid border-gray-700
                       shadow-lg rounded-lg transition-all duration-300 ${hoveredItem === index ? 'block' : 'hidden'
                    }`}
                >
                  <div className="flex flex-col">
                    {item.subItems.map((subItem, subIndex) => (
                      <Link to={subItem.link}
                        key={subIndex}
                        className="block p-2 text-gray-50 hover:bg-gray-100 rounded-md no-underline"
                      >
                        {subItem.title}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="hidden flex-row items-start justify-start max-w-full mq750:flex">
          <div className="flex-1 flex flex-col items-start justify-start pt-0.5 px-0 pb-0">
            <button onClick={toggleModal} className="[text-decoration:none] self-auto bg-transparent h-[135px] relative text-[inherit] flex items-center justify-center shrink-0">
              <img
                className="w-[50px] bg-white rounded-t-lg object-fit"
                alt=""
                src="/burger-menu-svgrepo-com.svg"
              />
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex flex-col justify-between overflow-auto bg-neutral-950/80 px-4 py-2 text-neutral-100 backdrop-blur visible opacity-100 transition-opacity duration-200">
          <nav className="grid grid-cols-1 text-xl leading-9 sm:text-2xl sm:leading-10 font-montserrat">
            <button onClick={toggleModal} className="mb-4 h-7 w-10 bg-neutral-950/80 border border-white hover:bg-gray-200 rounded-full flex items-center justify-center" aria-hidden="true">
              <svg className="h-6 w-6 text-white hover:text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round">
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            {menuItems.map((item, index) => (
              <div key={index} className="border-t border-neutral-400 py-4">
                <Link to={item.link} className="block font-normal no-underline text-white text-7xl" onClick={toggleModal}>
                  {item.title}
                </Link>
                {item.subItems && (
                  <div className="flex flex-col mt-2">
                    {item.subItems.map((subItem, subIndex) => (
                      <Link to={subItem.link}
                        key={subIndex}
                        className="text-lg text-gray-200 hover:text-gray-200 block my-1 no-underline"
                        onClick={toggleModal}
                      >
                        {subItem.title}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </nav>
          <Link to="/#home" className="mx-auto flex w-auto flex-row items-center justify-center mt-4" onClick={toggleModal}>
            <img
              className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
              loading="lazy"
              alt=""
              width="166" height="121"
              src="/image-7removebgpreview-1@2x.png"
            />
          </Link>
        </div>
      )}

      {/* Floating Social Links */}
      <div className="fixed right-4 bottom-4 flex flex-col items-center gap-4 z-[200]">
        <a href="https://www.linkedin.com/company/vantage-cybertech" target="_blank" className="h-[50px] w-[50px] bg-[#0072b1] rounded-full flex items-center justify-center box-border">
          <img
            className="w-[30px] h-[24px] relative"
            loading="lazy"
            alt="LinkedIn"
            src="/linked-in.svg"
          />
        </a>
        <a href="https://wa.me/447946760003?text=I’m%20ready%20to%20bring%20my%20ideal%20web%20application%20to%20reality.%20Let’s%20discuss%20my%20requirements!" target="_blank" className="h-[50px] w-[50px] rounded-full flex items-center justify-center box-border bg-[#25D366]">
          <img
            className="w-[30px] h-[35px] relative"
            loading="lazy"
            alt="WhatsApp"
            src="/w.svg"
          />
        </a>
      </div>
    </header>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
